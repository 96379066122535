
import React, { useState, useEffect } from 'react'
import {
    Grid,
    Box,
    Typography,
    Button,
  } from '@mui/material';

import ItemPanierPredef from 'components/common/ItemPanierPredef';
import { useNavigate } from "@pankod/refine-react-router-v6";
import Loader from 'components/common/Loader';
import {  useCtxt } from "contexts/app.context";
import panierImage from "../assets/panierPredef.svg"
import axios from 'axios';
import Swal from 'sweetalert2';
import { useMediaQuery } from '@pankod/refine-mui';
import { mobileWidth } from "components/constante";
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import { Add } from '@mui/icons-material';
const PaniersPredef = () => {
    const { ctxt } = useCtxt();    
    const navigate = useNavigate();
    const isMobile = useMediaQuery(mobileWidth)
    
    const days = [{
        id: 1,
        label: "Dimanche",
        value:"Sunday",
    },
    {
        id: 2,
        label: "Lundi",
        value: "Monday",
    },
    {
        id: 3,
        label: "M",
        value: "Tuesday",
    },
    {
        id: 4,
        label: "M",
        value: "Wednesday"
    },
    {
        id: 5,
        label: "J",
        value: "Thursday"
    },
    {
        id: 6,
        label: "V",
        value: "Friday"
    },
    {
        id: 7,
        label: "S",
        value: "Saturday"
    }]
    
    const [refresh,setRefresh]= useState(false)
    const [paniers,setPaniers] = useState<any>([])
   
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    useEffect(() => {
        axios.get("/api/orders/predefined/my_orders").then((res) => {
            if (res.status === 200){
               setPaniers(res.data)
            }
            setIsLoading(false)
        }).catch((e) => {
            console.log(e)
            setIsError(true)
            setIsLoading(false)
        })
        
    }, [refresh])
   
    const handleClick = () => {
        navigate("/panierPredefini/create")
    }
    const handleDelete = async (id: number) => {
       await axios.delete(`/api/orders/predefined/${id}`,{withCredentials: true})
       .then((res) => {
            if (res.status === 200) {
                Swal.fire("Succes", "Votre panier a bien été supprimé", "success");
                setRefresh(!refresh)
            }
            else {
                Swal.fire("Erreur", "Le panier n'a pas été supprimé", "error");
            }
        })
        .catch((e) => {
            console.log(e);
            Swal.fire("Erreur", "Le panier n'a pas été supprimé", "error");
        });
    }
    
   
 
    if (isLoading) return <Loader />;
    if (isError) return <Typography>Error...</Typography>;
  return (
    <Box sx={{ display:"flex", width: "100%",flexDirection:"column", justifyContent:"space-between",height:"100%"}}>
    {  <Box sx={{width: "100%", position: "relative", height: "100%", display: "flex", justifyContent:"space-between", margin: "10px 0"}}>
    <Box sx={{flex:"auto"}}>
    {paniers.length > 0 && ( 
    <>
    
    <Grid rowSpacing={{xs: 1, sm: 1, md: 1 }} columnSpacing={{xs: 0, md:2}} sx={{position: "relative", flex:"auto", gap:"20px", margin: "0px", background:"transparent", padding:"0 10px", display: "flex", flexDirection: "column"}}>
        {paniers?.filter( (pr : any) => {
            if (pr.name.includes(ctxt.recherche)){
                console.log(ctxt.recherche)
                return pr;
            }
            return false;
        }).map((panier : any) => {return (
            <ItemPanierPredef key={panier.id} panier={panier} handleDelete={handleDelete} handleClick={handleClick} />
        ) })}
    </Grid>
    
        </>
    )}
    {paniers.length===0 && (
        <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%", flexDirection:"column",gap:"20px"}}>
        <LocalGroceryStoreOutlinedIcon sx={{color:"#CFCFCF",fontSize:180}} />
        <Typography color="#CFCFCF">La liste des paniers prédéfini est vide</Typography>
    </Box>
    )}
    </Box>
            { !isMobile &&  <Box sx={{ flex: "1", display:"flex", flexDirection:"column",padding:"30px 0", alignItems:"center",gap:"10px",height:"fit-content",borderRadius:"10px", background:"#fff"}}>
                <img src={panierImage} alt="panier" style={{width:"30%"}} />
                <Typography sx={{color:"#EBEBEB",textAlign:"center",width:"80%"}}>créer un panier prédéfini et configurer vos commande</Typography>
                <Button
                variant="contained"
                color="secondary"
                size="large"
                sx={{
                height: 40,
                borderRadius: "3px",
                fontSize: "10px",
                fontWeight: "500",
                margin:"0 auto",
                fontWweight: 600,
                padding: "30px 46px"
                }}
                onClick={() => {
                handleClick()
                }}
                >
                    Créer un nouveau panier prédéfini
                </Button>
            </Box>}
            
    </Box>}
    {
        isMobile && <Box sx={{width:"100%",display:"flex", justifyContent:"center"}}>
            <Box sx={{ cursor:"pointer", background:"#fff",borderRadius:"20px", padding:"20px 20px",boxShadow:"0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);"}} onClick={() => {
        handleClick()
        }}>
            <Add sx={{ color:"#92CE08"}} />
            </Box>
        </Box>
    }
    
    </Box>
  )
}

export default PaniersPredef