
import React, { useState, useEffect } from 'react'
import {
    Grid,
    Box,
    Typography,
    Button,
  } from '@mui/material';

import CardPanier from 'components/common/CardPanier';
import { useNavigate } from "@pankod/refine-react-router-v6";
import { Produit } from 'interfaces/common';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import {  useCtxt } from "contexts/app.context";
import { Stack } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import axios from "axios"
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { useMediaQuery } from '@pankod/refine-mui';
import { mobileWidth } from "components/constante";
import Swal from 'sweetalert2';
const Panier = () => {
    const { ctxt, dispatch } = useCtxt();  
    const isMobile = useMediaQuery(mobileWidth)  
    const navigate = useNavigate();
    const [prix,setPrix] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const [products, setProducts] = useState<any[]>([])
    const [getData, setGetData] = useState(false)
    
    useEffect(() => {
        const produits = ctxt?.produitPanier.map((pr: Produit) => {
            
            return {
                productId: pr.id,
                name:pr.name,
                quantity:pr.quantity,
                collisage: pr.collisage,
                unit: pr.unit,
                price: pr.price,
                pricePromotion: pr.pricePromotion,
                image: pr.image
            }
        })
    setProducts(produits)
    }, [getData])
     useEffect(() => {
        let prixTotal = products.reduce(
            (total, pr) =>{
                return (pr.pricePromotion ? pr.pricePromotion : pr.price)*pr.quantity + total
                },
            0
          );
         
        setPrix(prixTotal)
    }, [products,refresh])
    
    
   
    
    const handleClick = () => {
        let total = 0
        const produits = products.map((pr: Produit) => {
            total += (pr.pricePromotion ? pr.pricePromotion : pr.price)*pr.quantity;
            return {
                productId: pr.productId,
                name:pr.name,
                quantity:pr.quantity,
                collisage: pr.collisage,
                unit: pr.unit,
                price: pr.price,
                pricePromotion: pr.pricePromotion,
                image: pr.image
            }
        })
        
        axios.post("/api/orders",{
            totalPrice: total,
            products: produits,
        },{withCredentials: true})
        .then((res) => {
            if (res.status === 200){
                dispatch({ type: "setPanier",payload: [] })
                navigate("/succes")
            }
            else {
                Swal.fire("Erreur", "Ta commande n'a pas été traité", "error");
            }
        })
        .catch((e) => {
            Swal.fire("Erreur", "Ta commande n'a pas été traité", "error");
        })
    }
    
   const handleDelete = (id: number) => {
    Swal.fire({
        title: 'Vous voulez supprimer le produit choisi?',
        showCancelButton: true,
        cancelButtonText: `Annuler`,
        confirmButtonText: 'Oui',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            let t = ctxt.produitPanier
            t.map((pr: Produit,index: number) => {
                if (pr.id === id) {
                    t.splice(index, 1);
                }
        })
            dispatch({ type: "setPanier",payload: t })
            setGetData(!getData)
        } 
      })
   }
  return (
    <>
    { !isMobile && <Box sx={{width: "100%", position: "relative", height: "100%", display: "flex", justifyContent: "center", margin: "10px 0"}}>
    { ( 
    <>
    
    {products.length > 0 && <Grid rowSpacing={{xs: 1, sm: 1, md: 1 }} columnSpacing={{xs: 0, md:2}} sx={{position: "relative", flex:"auto", gap:"20px", margin: "0px", background:"transparent", padding:"0 10px", display: "flex", flexDirection: "column"}}>
        {products?.filter( (pr: Produit) => {
            if (pr.name.includes(ctxt.recherche)){
                return pr;
            }
            return false;
        }).map((produit : any, index : number) => {return (
            <CardPanier key={produit.id} index={index} products={products} decrease={() => {
                if (products[index].quantity > 0){
                    let t = products
                  t[index].quantity -= 1
                  setProducts(t)
                  setRefresh(!refresh)
                  }
            }}  increase={() => {
                let t = products
                t[index].quantity += 1
                setProducts(t)
                setRefresh(!refresh)
            }} handleDelete={handleDelete} />
        ) })}
    </Grid>}
    {products.length===0 && 
        <Box sx={{width: "100%", position: "relative", display: "flex", flexDirection: "column",gap: "20px", justifyContent: "center", alignItems: "center"}}>
            <LocalGroceryStoreOutlinedIcon sx={{ fontSize: 100}} />
            <Typography sx={{ textAlign:"center"}} variant="h2">Le panier est vide</Typography>
      </Box>
            }
    <Stack sx={{display: "flex", flexDirection:"column", gap: "6px",width:"30%"}}>
        
            <Box sx={{display: "flex", alignItems: "center", gap: "6px",padding:"12px 14px", borderRadius: "10px", background:"white", cursor:"pointer"}} onClick={() => {
                navigate("/panierPredefini/create")
            }}>
                <AddIcon /> 
                <Typography sx={{ textTransform: "uppercase"}}>Panier prédéfinie</Typography>
            </Box>
            {products.length > 0 && <>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: "6px",padding:"12px 14px", borderRadius: "10px", background:"white"}}>
                <PaymentsOutlinedIcon />
                <Typography>{`${ctxt.user?.Client?.soldeInitial} DA`}</Typography>
            </Box>
            <Box sx={{padding:"12px 14px", borderRadius: "10px", background:"white"}} >
            <Box sx={{display: "flex", alignItems: "center", gap: "25px",padding:"12px 4px", justifyContent:"space-between"}}>
                <Box sx={{display: "flex", alignItems: "center", gap: "6px"}}>
                    <PaymentsOutlinedIcon />
                    <Typography>SOUS-TOTAL ({ctxt.produitPanier.length})</Typography>
                </Box>
                <Typography>{`${prix} DA`}</Typography>
            </Box>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    sx={{
                    height: 50,
                    borderRadius: "3px",
                    fontSize: "10px",
                    fontWeight: "500",
                    margin:"0 auto",
                    fontWweight: 600,
                    padding: "20px 30px"
                    }}
                    onClick={() => {
                    handleClick()
                    }}
                >
                    Commander
                </Button>
            </Box>
            </>}
    </Stack>
        </>
    )}
    
    </Box>}
    { isMobile && <Box sx={{width: "100%", height:"100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center",padding:"0 10px", marginBottom: "60px"}}>
    <Stack sx={{display: "flex", justifyContent: "space-between", flexDirection: "initial", margin:"10px 0", width: "100%"}}>
        
            <Box sx={{display: "flex", alignItems: "center", gap: "6px",padding:"12px 14px", borderRadius: "10px", background:"white", cursor:"pointer"}} onClick={() => {
                navigate("/panierPredefini/create")
            }}>
                <AddIcon /> 
                <Typography sx={{ textTransform: "uppercase"}}>Panier prédéfinie</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: "6px",padding:"12px 14px", borderRadius: "10px", background:"white"}}>
                <PaymentsOutlinedIcon />
                <Typography>{`${ctxt.user?.Client?.soldeInitial} DA`}</Typography>
            </Box>
    </Stack>
    {products.length > 0 && ( 
    <>
    <Grid container rowSpacing={{xs: 1, sm: 1, md: 2 }} columnSpacing={{xs: 0, md:2}} sx={{position: "relative", margin: "10px 0 10px", background:"transparent"}}>
        {products?.filter( (pr: Produit) => {
            if (pr.name.includes(ctxt.recherche)){
                return pr;
            }
            return false;
        }).map((produit : any, index) => {return (
            <CardPanier key={produit.id} index={index} products={products} decrease={() => {
                if (products[index].quantity > 0){
                    let t = products
                  t[index].quantity -= 1
                  setProducts(t)
                  setRefresh(!refresh)
                  }
            }}  increase={() => {
                let t = products
                t[index].quantity += 1
                setProducts(t)
                setRefresh(!refresh)
            }} handleDelete={handleDelete} />
        ) })}
    </Grid>
    <Stack sx={{display: "flex", flexDirection:"column", gap: "6px", width: "100%"}}>
            <Box sx={{padding:"12px 14px", borderRadius: "10px", background:"white"}} >
            <Box sx={{display: "flex", alignItems: "center", gap: "25px",padding:"12px 14px"}}>
                <Box sx={{display: "flex", alignItems: "center", gap: "6px"}}>
                    <PaymentsOutlinedIcon />
                    <Typography>SOUS-TOTAL ({ctxt.produitPanier.length})</Typography>
                </Box>
                <Typography>{`${prix} DA`}</Typography>
            </Box>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    sx={{
                    height: 50,
                    borderRadius: "3px",
                    fontSize: "10px",
                    fontWeight: "500",
                    margin:"0 auto",
                    fontWweight: 600,
                    padding: "20px 30px"
                    }}
                    onClick={() => {
                    handleClick()
                    }}
                >
                    Commander
                </Button>
            </Box>
    </Stack>
        </>
    )}
    {products.length===0 && (
        <Box sx={{width: "100%",height:"100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            
            <LocalGroceryStoreOutlinedIcon sx={{color:"#CFCFCF",fontSize:180}} />
            <Typography color="#CFCFCF" variant='h4'>Le panier est vide</Typography>
        
      </Box>
            )}
        
    </Box>}
    
    </>
  )
}

export default Panier