import {
    Grid,
    Box,
    Typography
  } from '@mui/material';

import moment from 'moment';
import { Icmd } from 'interfaces/common';
import { useMediaQuery } from '@pankod/refine-mui';
import { mobileWidth } from 'components/constante';
type ICardCommande = {
  cmd: Icmd;
  handleClick : (id: number) => void;
}
const CardCommande = ({cmd, handleClick}:ICardCommande) => {
  
  const isMobile = useMediaQuery(mobileWidth)
  return (
    <Grid item xs={12} sx={{height: "fit-content", cursor:"pointer"}} onClick={() => {
      handleClick(cmd.id)
    }}>
         {!isMobile && <Box sx={{display: "flex", alignItems: "flex-start", gap:"50px", background: "#fff", borderRadius: "13px", padding: "19px 16px"}}>
            <Box sx={{flex: "auto", padding: "0 10px", display: "flex", gap: "10px", flexDirection: "column", fontWeight: "bold"}}>
                <Typography sx={{ display:"flex", alignItems:"center",gap:"5px"}} > #Com <Typography sx={{fontWeight:"bold"}}>{cmd?.id}</Typography> </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap:"10px"}}>
                <Typography>SOUS-TOTAL ({cmd.orders_details.length})</Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection:"column", alignItems:"flex-end", gap: "10px"}}>
                <Typography>{moment(cmd.createdAt).format("YYYY/MM/DD HH:mm")}</Typography>
                <Typography sx={{fontWeight:"bold"}}>{`${cmd.totalPrice} DA`}</Typography>
                {
                    cmd.state === "En attente" && <Typography sx={{color:"#F9A400"}}>En cour de traitement </Typography>
                }
                {
                    cmd.state === "En préparation" && <Typography sx={{color:"#E56E00"}}>En cour de préparation </Typography>
                }
                {
                    cmd.state === "Livrée" && <Typography sx={{color:"#92CE08"}}>livrée </Typography>
                }
                {
                    cmd.state === "Annulée" && <Typography sx={{color:"#C80000"}}>Annulée</Typography>
                }
                
            </Box>
        </Box>}
        {isMobile && <Box sx={{ background: "#fff", borderRadius: "13px", padding: "19px 16px"}}>
          <Box sx={{width:"100%",display: "flex", alignItems: "flex-start", gap:"10px", marginBottom:"10px", justifyContent:"space-between"}}>
            <Box sx={{flex: "none", display: "flex", gap: "10px", flexDirection: "column", fontWeight: "bold"}}>
                  <Typography sx={{ display:"flex", alignItems:"center",gap:"5px"}} > #Com <Typography sx={{fontWeight:"bold"}}>{cmd?.id}</Typography> </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap:"10px"}}>
                  <Typography>SOUS-TOTAL ({cmd.orders_details.length})</Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection:"column", alignItems:"flex-end", gap: "10px"}}>
                  <Typography>{moment(cmd.createdAt).format("YYYY/MM/DD HH:mm")}</Typography>
                  <Typography sx={{fontWeight:"bold"}}>{`${cmd.totalPrice} DA`}</Typography> 
              </Box>
          </Box>
            
            <Box sx={{ textAlign:"end"}}>
            {
                    cmd.state === "En attente" && <Typography sx={{color:"#F9A400"}}>En cour de traitement </Typography>
                }
                {
                    cmd.state === "En préparation" && <Typography sx={{color:"#E56E00"}}>En cour de préparation </Typography>
                }
                {
                    cmd.state === "Livrée" && <Typography sx={{color:"#92CE08"}}>livrée </Typography>
                }
                {
                    cmd.state === "Annulée" && <Typography sx={{color:"#C80000"}}>Annulée</Typography>
                }
            </Box>
        </Box>}
        
        
    </Grid>
  )
}

export default CardCommande