

import {
  Stack,Box
} from "@pankod/refine-mui";
import { useNavigate } from "@pankod/refine-react-router-v6";
import { FavoriteBorder, ShoppingCartOutlined, Store, Person } from '@mui/icons-material';
const NavbarMobile = () => {
    
    const navigate = useNavigate();
    const links = [
      {id: 1,name:"products",label: "Produits", icon: <Store sx={{ fontSize: 30}} />, navigate: "/products"},
      {id: 4,name:"panier",label: "Voir panier", icon: <ShoppingCartOutlined sx={{ fontSize: 30}} />, navigate: "/panier"},
      {id: 2,name:"favorites",label: "Favoris", icon: <FavoriteBorder sx={{ fontSize: 30}} />, navigate: "/favorites"},
      {id: 5,name:"profil",label: "Profil", icon: <Person sx={{ fontSize: 30}} />, navigate: "/profil"},
    ]
  return (
     <Stack sx={{background:"#000",padding:"7px 14px", borderRadius:"10px"}}>
      <Box sx={{display:"flex",gap:"10px", justifyContent:"space-between"}}>
          {links.map((link) => {return (
            <Box key={link.id} sx={{ display:"flex", gap:"10px", alignItems:"center", justifyContent: "space-between", padding:"13px 17px",borderRadius:"9px",color: window.location.pathname === link.navigate ? "#AFE259": "#fff",cursor:"pointer","&:hover": {
              color: "#c7e88f",
            },}} onClick={() => {navigate(`${link.navigate}`)}}>
              <Box sx={{display:"flex", gap:"10px"}}>
              {link.icon}
              </Box>
          </Box>
          )})}
          
      </Box>
      
  </Stack>
  )
}

export default NavbarMobile