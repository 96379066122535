import React, { useEffect, useState } from 'react'
import {
    Grid,
    Box,
    Typography,
    Button,
    Checkbox, Skeleton
  } from '@mui/material';
  import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { ICardProduit } from 'interfaces/common';
import axios from 'axios';
import { FormControl } from '@pankod/refine-mui';

const CardProduit = ({produit, handleClick}:ICardProduit) => {
  const [refresh,setRefresh]= useState(false)
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {

  }, [refresh])
  
  const removeFavorite = async (productId: number) => {
    await axios.delete('/api/favorites/'+productId, {
        withCredentials: true
    })
  }

  const addFavorite = async (productId: number) => {
      await axios.post('/api/favorites/', 
      {productId}, 
      {
          withCredentials: true
      })
  }

  const toggleFavorite = () => {
      produit.favorite === true ? removeFavorite(produit.id) : addFavorite(produit.id)
      setRefresh(!refresh)
  };

  return (
    <Grid item xs={5.6} md={2.4} >
        <Box sx={{ background: "#fff",height:"100%", justifyContent:"space-between", width:"fit-content", padding: "20px 5px", display: "flex", flexDirection:"column", gap:"5px", borderRadius: "10px", position: "relative"}}>
        <Box style={{ position: "absolute",top: "20px",right: "3%"}}>
          <FormControl>
            {produit.favorite ? <Checkbox onChange={toggleFavorite} aria-label='favoris' icon={<FavoriteBorderIcon sx={{color: "#000"}} />} checkedIcon={<FavoriteIcon />} defaultChecked /> : <Checkbox onChange={toggleFavorite} aria-label='favoris' icon={<FavoriteBorderIcon sx={{color: "#000"}} />} checkedIcon={<FavoriteIcon />} /> }
          </FormControl>  

        </Box>
        <Box sx={{flex:"auto"}}>
        {produit.image && (
            <>
              {!imageLoaded && <Skeleton variant="rectangular" width="100%" height={100} />}
              <img
                src={`/uploads/${produit.image}`}
                style={{ width: "90%", margin: "0 auto", display:"block", height:"100%", objectFit:"contain" }}
                onLoad={() => setImageLoaded(true)}
              />
            </>
          )}
        </Box>
        <Box>
        <Typography>{produit.name}</Typography>
        <Typography>{produit.category.name}</Typography>
        {
            produit.pricePromotion && <Box>
               <Typography sx={{ fontWeight: "bold", color:"#92CE08"}}>{`${produit.pricePromotion}.00 DA`}</Typography>
              <Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
                <Typography sx={{ fontWeight: "bold",color: "#C2C2C2", textDecoration:"line-through"}}>{`${produit.price}.00 DA`}</Typography>
                <Typography sx={{ fontSize:"0.8rem", color:"#92CE08"}}>{`-${100- Math.round(produit.pricePromotion*100/produit.price)}%`}</Typography>
              </Box>
          
          
            </Box>
          }
          {
            !produit.pricePromotion  && <Typography sx={{ fontWeight: "bold"}}>
          
            {`${produit.price}.00 DA`}</Typography>
          }
        </Box>
        
        <Button
        variant="contained"
        color="primary"
        size="small"
        fullWidth
        sx={{
        height: 30,
        borderRadius: "3px",
        fontSize: "10px",
        fontWeight: "500"
        }}
        onClick={() => {
          handleClick(produit)
        }}
        >
            Ajouter au panier
        </Button>
        </Box>
        
    </Grid>
  )
}

export default CardProduit