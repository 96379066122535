import React, { createContext, useContext, useReducer } from "react";
import { IUser} from "interfaces/common"
import { Produit} from "interfaces/common"
type stateData = {
  user: IUser | null;
  refresh: boolean;
  recherche: string;
  produitPanier: Produit[];
};

type AppAction =
  | {
      type: "refresh";
      refresh: boolean;
    }
  | {
      type: "setUser";
      payload: IUser | undefined;
    }
  |
  {
    type: "setPanier";
    payload: Produit[];
  }
  |
    {
      type: "setRecherche";
      payload: string;
    };

const appProducer = (
  state: stateData,
  action: AppAction
): stateData => {

  switch (action.type) {
    case "refresh": {
      return { ...state, refresh: !state.refresh };
    }
    case "setRecherche": {
      return { ...state, recherche: action.payload };
    }
    case "setPanier": {
      localStorage.setItem("products", JSON.stringify(action.payload))
      return { ...state, produitPanier: action.payload };
    }
    case "setUser": {
      if (action.payload) {
        return { ...state, user: action.payload};
      } else {
        return { ...state, user: null };
      }
    }

    default:
      return { ...state };
  }
};

const defaultValues: stateData = {
  user: null,
  refresh: true,
  recherche: '',
  produitPanier: JSON.parse(localStorage.getItem("products") || "[]"),
};

const myState = {
  ctxt: defaultValues,
  dispatch: (action: AppAction): void => {},
};

const AppContext = createContext<{
  ctxt: stateData;
  dispatch: React.Dispatch<AppAction>;
}>(myState); //initialize context with default value

interface Props {
  children: React.ReactNode;
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  const [ctxt, dispatch] = useReducer(appProducer, defaultValues);

return (
    <AppContext.Provider value={{ ctxt, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export const useCtxt: any = () => useContext(AppContext);
