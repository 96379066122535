
import {
    Grid,
    Box,
    Typography,
  } from '@mui/material';

import CardCommande from 'components/common/CardCommande';
import { useNavigate } from "@pankod/refine-react-router-v6";
import {  useList } from "@pankod/refine-core";
import { Produit } from 'interfaces/common';
import Loader from 'components/common/Loader';
import {  useCtxt } from "contexts/app.context";
import useMediaQuery from '@mui/material/useMediaQuery';
import panierImage from "../assets/panierPredef.svg"
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import { mobileWidth } from "components/constante";
const Commandes = () => {  
    const isMobile = useMediaQuery(mobileWidth)  
    const navigate = useNavigate();

    const { data, isLoading, isError }= useList<Produit>({
        resource: "orders/my_orders",
    });
    
    
   
    /* @ts-ignore */
    const commandes = data?.data ?? [];
    const handleClick = (id: number) => {
        navigate(`/commandes/show/${id}`)
    }
    
   
 
    if (isLoading) return <Loader />;
    if (isError) return <Typography>Error...</Typography>;
  return (
    <Box sx={{ display:"flex", width: "100%"}}>
    { <Box sx={{width: isMobile ? "100% ":"80%", position: "relative", height: "100%", display: "flex", justifyContent: "space-between", margin: "10px 0"}}>
    {commandes.length > 0 && ( 
    <>
    
    <Grid rowSpacing={{xs: 1, sm: 1, md: 1 }} columnSpacing={{xs: 0, md:2}} sx={{position: "relative", flex:"auto", gap:"20px", margin: "0px", background:"transparent", padding:"0 10px", display: "flex", flexDirection: "column-reverse"}}>
        {commandes.map((cmd : any) => {return (
            <CardCommande key={cmd.id} cmd={cmd} handleClick={handleClick} />
        ) })}
    </Grid>
    
        </>
    )}
        {commandes.length===0 && (
          <Box>
          <LocalGroceryStoreOutlinedIcon />
          <Typography>Vous n'avez pas encore effectuer de commande</Typography>
      </Box>
            )}
    </Box>}
    
    </Box>
  )
}

export default Commandes