import {CircularProgress, Box} from "@mui/material"
import logo from "assets/logo.svg"
const Loader = () => {
  return (
    <div className='login-container'>
        <Box
        padding={20}
        sx={{ minHeight: "100vh", display: 'flex', justifyContent: 'flex-start', alignItems:'center', flexDirection:'column' ,gap:"70px" }}
        >
            <img src={logo} alt="logo" style={{"width":"200px"}} />
            <CircularProgress size={80}   />
        </Box>
    </div>
    
  )
}

export default Loader