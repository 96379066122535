import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

import {
  Stack,
  Typography,Box
} from "@pankod/refine-mui";
import { useNavigate } from "@pankod/refine-react-router-v6";
import {  useCtxt } from "contexts/app.context";
import commandes from "../../assets/icons/commande_icon.svg"
import products from "../../assets/icons/products_icon.svg"
import panier from "../../assets/icons/panier_icon.svg"
import favorite from "../../assets/icons/favorites_icon.svg"
const Navbar = () => {
  const { ctxt:{user} } = useCtxt();
    
    const navigate = useNavigate();
    const links = [
      {id: 1,name:"products",label: "Produits", icon: products, navigate: "/products"},
      {id: 2,name:"favorites",label: "Favoris", icon: favorite, navigate: "/favorites"},
      {id: 3,name:"commandes",label: "Commandes", icon: commandes, navigate: "/commandes"},
      {id: 4,name:"panier",label: "Voir panier", icon: panier, navigate: "/panier"},
      {id: 5,name:"panierPredefini",label: "Paniers prédéfinis", icon: panier, navigate: "/panierPredefini"},
    ]
  return (
     <Stack sx={{ padding: "10px 20px"}}>
      <>
      <Box sx={{display:"flex", flexDirection:"column",gap:"10px", background:"#fff",padding:"12px 14px", margin:"10px 0", borderRadius:"10px"}}>
          {links.map((link) => {return (
            <Box key={link.id} sx={{ display:"flex", gap:"10px", alignItems:"center", justifyContent: "space-between", padding:"13px 17px",borderRadius:"9px",background: window.location.pathname === link.navigate ? "#D4FFF7": "#fff",cursor:"pointer","&:hover": {
              backgroundColor: "#E8FFFB",
            },}} onClick={() => {navigate(`${link.navigate}`)}}>
              <Box sx={{display:"flex", gap:"10px"}}>
              <img src={link.icon} alt="icon image" />
              <Typography sx={{fontSize: "15px", fontWeight: "bold", width:"max-content", textTransform: "uppercase"}}>{link.label}</Typography>
              </Box>
              <KeyboardArrowRightOutlinedIcon />
          </Box>
          )})}
          
      </Box>
      </>
      
  </Stack>
  )
}

export default Navbar