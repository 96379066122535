import React from "react";
import { LayoutProps } from "@pankod/refine-core";
import { Box } from "@pankod/refine-mui";

import { Header as DefaultHeader } from "../header";
import Navbar from "components/common/Navbar";
import NavbarMobile from "components/common/NavbarMobile";
import { mobileWidth } from "components/constante";
import { useMediaQuery } from "@pankod/refine-mui";
export const Layout: React.FC<LayoutProps> = ({
  Header,
  children,
}) => {
  const HeaderToRender = Header ?? DefaultHeader;
  const isMobile = useMediaQuery(mobileWidth)
  const subPagesMobile : any = {
    "/commandes/show":"Details de la commande",
    "/panierPredefini":"panier prédéfini",
    "/panierPredefini/create":"nouveau panier",
    "/panierPredefini/show":" modifier le panier",
    "/profil":"VOTRE PROFILE",
    "/commandes":"Commandes"

  }
  return (
    <Box display="flex" flexDirection="column" position="relative" className="container">
      <HeaderToRender />
      <Box
        sx={{
          display: "flex",
          flexDirection: "raw",
          flex: "auto",
          marginBottom: isMobile ? "10px":"0",
          overflowY: "auto",
          height: "100%",
        }}
        
      >
        {!isMobile && <Navbar />}
        <Box
          component="main"
          sx={{
            p: { xs: 1, md: 2, lg: 3 },
            flexGrow: 1,
            overflowY: "auto",
          }}
        >
          {children}
        </Box>
      </Box>
      {isMobile && !subPagesMobile[window.location.pathname.split("/", 3).join("/")] && <Box sx={{ position:"fixed", left:"0", bottom:"0", width:"100%"}}>
        <NavbarMobile />
      </Box> }
      
    </Box>
  );
};
