import React, { useEffect, useState } from 'react'
import {
    Grid,
    Box,
    Typography,
  } from '@mui/material';
import { ICardPredefindedPanier } from 'interfaces/common';

import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useNavigate } from "@pankod/refine-react-router-v6";
import Swal from 'sweetalert2';
import { useMediaQuery } from '@pankod/refine-mui';
import { mobileWidth } from "components/constante";
const ItemPanierPredef = ({panier, handleDelete, handleClick} : ICardPredefindedPanier) => {
  const isMobile = useMediaQuery(mobileWidth)
  const navigate = useNavigate();
  const [refresh,setRefresh]= useState(false)

  useEffect(() => {

  }, [refresh])
  
  const deleteTrigger = () => {
    Swal.fire({
      title: 'Vous voulez supprimer le panier choisi?',
      showCancelButton: true,
      cancelButtonText: `Annuler`,
      confirmButtonText: 'Oui',
    }).then( (result) => {
      if (result.isConfirmed) {
        handleDelete(panier.id)
      } 
    })
  }
  return (
    <Grid item xs={12} sx={{height: "fit-content"}}>
         { <Box sx={{display: "flex", alignItems: "center", gap:"50px", background: "#fff", borderRadius: "13px", padding: "19px 16px"}}>
            <Box>
                <LocalGroceryStoreOutlinedIcon sx={{ fontSize: isMobile ? 30 :60}} />
            </Box>
            <Box sx={{flex: "auto", padding: "0 10px", display: "flex", gap: "10px", flexDirection: "column", fontWeight: "bold"}}>
                <Typography sx={{ display:"flex", alignItems:"center",gap:"5px"}} > Panier <Typography sx={{fontWeight:"bold"}}>{panier?.name}</Typography> </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap:"10px"}}>
                <Typography>{`SOUS-TOTAL (${panier.products.length})`}</Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "10px"}}>
                <DeleteOutlinedIcon sx={{ cursor: "pointer"}} onClick={() => {
                  deleteTrigger()
                }} />
                <EditOutlinedIcon sx={{ cursor: "pointer"}} onClick={() => {
                  navigate(`/panierPredefini/show/${panier.id}`)
                }} />
            </Box>
        </Box>}
        
    </Grid>
  )
}

export default ItemPanierPredef