import React, { useState, useEffect } from 'react'
import {
    Grid,
    Box,
    Typography,
    Button,TextField,InputAdornment
  } from '@mui/material';
import CardPanierPredef from 'components/common/CardPanierPredef';
import { useNavigate,useParams } from "@pankod/refine-react-router-v6";
import {  useList } from "@pankod/refine-core";
import { Produit } from 'interfaces/common';
import Loader from 'components/common/Loader';
import {  useCtxt } from "contexts/app.context";
import useMediaQuery from '@mui/material/useMediaQuery';
import ModalProduct from 'components/common/ModalProduct';
import axios from 'axios';
import Swal from 'sweetalert2';
import { mobileWidth } from 'components/constante';
const PanierPredefCreate = () => {
    const { id } = useParams();
    const { ctxt } = useCtxt();    
    const navigate = useNavigate();

    const { data, isLoading, isError }= useList<Produit>({
        resource: "products",
    });

    const isMobile = useMediaQuery(mobileWidth)
   
    
   
    /* @ts-ignore */
    const products = data?.data ?? [];
    const [name,setName] = useState('')
   
    const days = [{
        id: 1,
        label: "D",
        value:"Sunday",
    },
    {
        id: 2,
        label: "L",
        value: "Monday",
    },
    {
        id: 3,
        label: "M",
        value: "Tuesday",
    },
    {
        id: 4,
        label: "M",
        value: "Wednesday"
    },
    {
        id: 5,
        label: "J",
        value: "Thursday"
    },
    {
        id: 6,
        label: "V",
        value: "Friday"
    },
    {
        id: 7,
        label: "S",
        value: "Saturday"
    }]
    
    const [values, setValues] = useState<boolean[]>([false,false,false,false,false,false,false])
    const [refresh,setRefresh] = useState(false)
    useEffect(() => {
      
    }, [refresh])
    const [selectedItem, setSelectedItem] = useState<Produit>()
    const [openModal, setOpenModal] = useState(false);
    const handleClose = () => {
        setOpenModal(false)
        setRefresh(!refresh)
    };
    const clickProduit = (item : Produit) => {
      setSelectedItem(item)
      setOpenModal(true)
    }
    const handleDelete = (id: number) => {
        const t = items
        t.map((e,index) => {
          if (e.id === id){
            t.splice(index, 1);
          }
        })
        setItems(t)
        setRefresh(!refresh)
    }
    const [items, setItems] = useState<Produit[]>([]);
    useEffect(() => {
      console.log(items)
    
    }, [refresh])
    
  const handleClick = async () => {
    let t : any[] = []
    values.map((v,index) => {
        if (v === true) {
            t.push(days[index].value)
        }
    })
    const daysToString = t.toString()
    const products = items.map((item) => {
        return {
            productId: item.id, quantity: item.quantity
        }
    })
    await axios.post("/api/orders/predefined/", {
        days : daysToString, products,name
    }, {withCredentials: true})
    .then((res) => {
        if (res.status === 200) {
            Swal.fire("Succes", "Votre panier a bien été crée", "success");
            navigate("/panierPredefini")
        }
        else {
            Swal.fire("Erreur", "Le panier n'a pas été créé", "error");
        }
    })
    .catch((e) => {
        console.log(e);
        Swal.fire("Erreur", "Le panier n'a pas été créé", "error");
      });
  }
  
  
    if (isLoading) return <Loader />;
    if (isError) return <Typography>Error...</Typography>;
  return (
    <Box sx={{postion:"relative", background: "transparent"}}>
        <ModalProduct item={selectedItem} open={openModal} handleClose={handleClose} type="predef" items={items} setItems={setItems} />
            {products.length > 0 && (
            <Box sx={{ width: "100%", display: "flex", gap:"10px", flexDirection: isMobile ? "column-reverse":"row"}}>
                
                <Grid container rowSpacing={{xs: 1, sm: 1, md: 2 }} columnSpacing={2} sx={{flex: "auto",position: "relative", background:"transparent", padding:"0 10px", marginBottom: "40px"}}>
                    {products?.filter( (pr) => {
                        if (pr.name.includes(ctxt.recherche)){
                            return pr;
                        }
                        return false;
                    }).map((produit : any) => {
                        let trouve = false
                        items?.map((el) => {
                            if (el.id === produit?.id){
                            trouve = true;
                            }
                        })
                        return (
                        <CardPanierPredef key={produit.id} produit={produit} trouve={trouve} handleDelete={handleDelete} handleClick={() => {
                            clickProduit(produit)
                        }} />
                        
                    ) })}
                </Grid>
                <Box sx={{ background: "#fff", height: "fit-content", display: "flex", flexDirection: "column", gap:"10px", padding: "20px 10px", borderRadius:"11px"}}>
                    <Box sx={{display: "flex", gap:"5px"}}>
                    <TextField
                    fullWidth
                    name="nom"
                    label="Nom du panier"
                    placeholder="Nom du panier"
                    type="text"
                    id="outlined-basic" variant="outlined"
                    onChange={(e: any) => {
                        setName(e.target.value)
                    }}
                    size="small"
                    sx={{backgroundColor: "#ffffff"}}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">{''}</InputAdornment>
                        ),
                      }}
                    />

                    </Box>
                    <Box sx={{ display:"flex",gap:"10px", justifyContent:"space-around"}}>
                        {
                            days.map((day) => {
                                if (values[day.id - 1]=== false) return <Box key={day.id} sx={{ color:"#929292", background: "#D9D9D9", borderRadius: "50%",cursor:"pointer", padding: "5px 10px" }} onClick={() => {
                                    const t = values
                                    t[day.id - 1] = true
                                    setValues(t)
                                    setRefresh(!refresh)
                                }}>
                                <Typography>{day.label}</Typography>
                            </Box>
                            if (values[day.id - 1]=== true) return <Box key={day.id} sx={{ color:"#fff", background: "#92CE08", borderRadius: "50%", padding: "5px 10px", cursor:"pointer" }} onClick={() => {
                                    const t = values
                                    t[day.id - 1] = false
                                    setValues(t)
                                    setRefresh(!refresh)
                                }}>
                                <Typography>{day.label}</Typography>
                            </Box>
                                
                            })
                        }
                    </Box>
                     <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    fullWidth
                    sx={{
                    height: 40,
                    borderRadius: "3px",
                    fontSize: "10px",
                    fontWeight: "500",
                    margin:"0 auto"
                    }}
                    onClick={() => {
                    handleClick()
                    }}
                    >
                        Valider
                    </Button>
                </Box>
            </Box>
            )}
            {products.length===0 && (
                <Typography>Aucun produit trouvé</Typography>
            )}
    </Box>
  )
}

export default PanierPredefCreate