
import React, { useState, useMemo } from 'react'
import {
    Grid,
    Box,
    Typography,
    Button,
  } from '@mui/material';
  import { LogoutOutlined, KeyboardArrowRightOutlined } from "@mui/icons-material";
  import {  useLogout } from "@pankod/refine-core";
import { useNavigate } from "@pankod/refine-react-router-v6";
import {  useCtxt } from "contexts/app.context";
import useMediaQuery from '@mui/material/useMediaQuery';
import { mobileWidth } from "components/constante";
import commandes from "../assets/icons/commande_icon.svg"
import panier from "../assets/icons/panier_icon.svg"
const Profil = () => {  
    const isMobile = useMediaQuery(mobileWidth)  
    const navigate = useNavigate();
    const { mutate: logout } = useLogout();
    const { ctxt: {user} } = useCtxt();
    
    const links = [
        {id: 3,name:"commandes",label: "Commandes", icon: commandes, navigate: "/commandes"},
        {id: 4,name:"panier",label: "Voir panier", icon: panier, navigate: "/panier"},
        {id: 5,name:"panierPredefini",label: "Paniers prédéfinis", icon: panier, navigate: "/panierPredefini"},
      ]
 if (!isMobile){
    navigate("/products")
 }
 return <Box>
    {isMobile && <Box sx={{ width:"100%", padding:"30px 20px"}}>
        <Box sx={{ display:"flex", width: "100%",flexDirection:"column",gap:"20px",marginBottom:"50px"}}>
            <Box sx={{ display:"flex", justifyContent:"space-between", gap:"10px"}}>
            <Typography sx={{color:"#6D6D6D"}}>Nom</Typography>
            <Typography sx={{ fontWeight:"bold"}} >{user?.Client?.gerant} </Typography>
            </Box>
            <Box sx={{ display:"flex", justifyContent:"space-between", gap:"10px"}}>
            <Typography sx={{color:"#6D6D6D"}}>Email</Typography>
            <Typography sx={{ fontWeight:"bold"}}>{user?.email}</Typography>
            </Box>
            <Box sx={{ display:"flex", justifyContent:"space-between", gap:"10px"}}>
            <Typography sx={{color:"#6D6D6D"}}>Numéro de téléphone</Typography>
            <Typography sx={{ fontWeight:"bold"}}>{user?.phone_number}</Typography>
            </Box>
            
        </Box>
        <Box sx={{width:"100%",display:"flex", flexDirection:"column",gap:"20px",padding:"12px 0px", margin:"10px 0", borderRadius:"10px"}}>
          {links.map((link) => {return (
            <Box key={link.id} sx={{ display:"flex", gap:"10px", alignItems:"center", justifyContent: "space-between", padding:"13px 17px",borderRadius:"9px",background: window.location.pathname === link.navigate ? "#D4FFF7": "#fff",cursor:"pointer","&:hover": {
              backgroundColor: "#E8FFFB",
            },}} onClick={() => {navigate(`${link.navigate}`)}}>
              <Box sx={{display:"flex", gap:"10px"}}>
              <img src={link.icon} alt="icon image" />
              <Typography sx={{fontSize: "15px", fontWeight: "bold", width:"max-content", textTransform: "uppercase"}}>{link.label}</Typography>
              </Box>
              <KeyboardArrowRightOutlined />
          </Box>
          )})}
          <Box sx={{ display:"flex", gap:"10px", alignItems:"center", justifyContent: "space-between",background:"#FFE8E8",color:"#C80000",borderRadius:"9px", padding:"13px 17px",cursor:"pointer"}} onClick={() => {logout()}}>
            <Box sx={{display:"flex", gap:"10px", alignItems:"center"}}>
            <LogoutOutlined sx={{color:"#C80000"}} />
            <Typography sx={{fontSize: "12px", width:"max-content", textTransform: "uppercase"}}>Déconnexion</Typography>
            </Box>
            <KeyboardArrowRightOutlined />
            </Box>
          
      </Box>
    </Box>}
    
 </Box>
}

export default Profil