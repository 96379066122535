import {
    Box,
    Typography,
    Button,
  } from '@mui/material';


import { useNavigate } from "@pankod/refine-react-router-v6";
import car from "../assets/car_comming.svg"
const CommandeRedirect = () => {
    const navigate = useNavigate();

  return (
    <Box sx={{postion:"relative", background: "transparent", maxWidth: 600, display:"flex", flexDirection:"column",gap:"20px", justifyContent:"center", alignItems:"center", padding:"120px 0",margin:"0 auto"}}>
        <Box sx={{ width:"fit-content"}}>
            <img src={car} alt="car image" style={{ width:"100%"}} />
        </Box>
        <Typography variant="h4" sx={{fontWeight:"bold"}}>Merci</Typography>
        <Typography sx={{ color:"#CFCFCF"}}>Votre commande est en cours de traitement</Typography>
        <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
            height: 50,
            borderRadius: "3px",
            fontSize: "10px",
            fontWeight: "500",
            margin:"0 auto",
            fontWweight: 600,
            padding: "20px 30px"
            }}
            onClick={() => {
            navigate("/commandes")
            }}
        >
            Voir vois commandes
        </Button>

    </Box>
  )
}

export default CommandeRedirect