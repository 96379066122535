import React, { useState, useEffect } from 'react'
import {
    Grid,
    Box,
    Typography,
    Button, TextField,Switch,InputAdornment
  } from '@mui/material';
import CardPanierPredef from 'components/common/CardPanierPredef';
import { useNavigate,useParams } from "@pankod/refine-react-router-v6";
import { useTable, useList,useOne } from "@pankod/refine-core";
import { Produit } from 'interfaces/common';
import Loader from 'components/common/Loader';
import {  useCtxt } from "contexts/app.context";
import axios from 'axios';

import ModalProduct from 'components/common/ModalProduct';
import Swal from 'sweetalert2';
import { useMediaQuery } from '@pankod/refine-mui';
import { mobileWidth } from 'components/constante';
const PanierPredef = () => {
    const { id } = useParams();
    const { ctxt } = useCtxt();    
    const navigate = useNavigate();
    const { data, isLoading, isError }= useOne({
        resource: "orders/predefined",
        id: id as string,
    });
    const { data: data2, isLoading: isLoading2, isError:isError2 }= useList<Produit>({
        resource: "products",
    });
    
    const products = data2?.data ?? [];
    const panierAvant = data?.data ?? {};
    const isMobile = useMediaQuery(mobileWidth)
    
    const [name,setName] = useState(panierAvant.name)
   const [state, setState] = useState(panierAvant.active)
    const days = [{
        id: 1,
        label: "D",
        value:"Sunday",
    },
    {
        id: 2,
        label: "L",
        value: "Monday",
    },
    {
        id: 3,
        label: "M",
        value: "Tuesday",
    },
    {
        id: 4,
        label: "M",
        value: "Wednesday"
    },
    {
        id: 5,
        label: "J",
        value: "Thursday"
    },
    {
        id: 6,
        label: "V",
        value: "Friday"
    },
    {
        id: 7,
        label: "S",
        value: "Saturday"
    }]
    
    const [values, setValues] = useState<boolean[]>([false,false,false,false,false,false,false])
    const [refresh,setRefresh] = useState(false)
    const [items, setItems] = useState<any[]>([]);
    useEffect(() => {
        let a = panierAvant.products?.map((el : any) => {
            return {...el, quantity: el.predefined_orders_details.quantity}
        })
        setItems(a)
      setName(panierAvant.name)
      setState(panierAvant.active)
      let t = panierAvant.days?.split(",")
      let t2 : boolean[] = []
      days.map((d,index) => {
        if (t?.includes(d.value)){
            t2[index] = true
        }
        else {
            t2[index] = false
        }
      })
      setValues(t2)
    }, [ panierAvant])
    const [selectedItem, setSelectedItem] = useState<Produit>()
    const [openModal, setOpenModal] = useState(false);
    const handleClose = () => {
        setOpenModal(false)
        setRefresh(!refresh)
    };
    const clickProduit = (item : Produit) => {
      setSelectedItem(item)
      setOpenModal(true)
    }
   
    const handleDelete = (id: number) => {
        const t = items
        t.map((e,index) => {
          if (e.id === id){
            t.splice(index, 1);
          }
        })
        setItems(t)
        setRefresh(!refresh)
    }
    
    
  const handleSubmit = async () => {
    let t : any[] = []
    values.map((v,index) => {
        if (v === true) {
            t.push(days[index].value)
        }
    })
    const daysToString = t.toString()
    const products = items.map((item) => {
        return {
            productId: item.id, quantity: item.quantity
        }
    })
    await axios.put(`/api/orders/predefined/${panierAvant.id}`,{
        days : daysToString, products,name,active: panierAvant.active
    }, {withCredentials: true})
    .then((res) => {
        if (res.status === 200) {
            Swal.fire("Succes", "Votre panier a bien été mis a jour", "success");
            navigate("/panierPredefini")
        }
        else {
            Swal.fire("Erreur", "Le panier n'a pas été mis a jour", "error");
        }
    })
    .catch((e) => {
        console.log(e);
        Swal.fire("Erreur", "La mis a jour du panier a échoué", "error");
      })
  }
  const disablePanier = async () => {
    let t : any[] = []
    values.map((v,index) => {
        if (v === true) {
            t.push(days[index].value)
        }
    })
    const daysToString = t.toString()
    const products = items.map((item) => {
        return {
            productId: item.id, quantity: item.quantity
        }
    })
    await axios.put(`/api/orders/predefined/${panierAvant.id}`,{
        days : daysToString, products,name,active: !panierAvant.active
    }, {withCredentials: true})
    .then((res) => {
        if (res.status === 200) {
            Swal.fire("Succes", "Votre panier a bien été mis a jour", "success");
            navigate("/panierPredefini")
        }
        else {
            Swal.fire("Erreur", "Le panier n'a pas été mis a jour", "error");
        }
    })
    .catch((e) => {
        console.log(e);
        Swal.fire("Erreur", "La mis a jour du panier a échoué", "error");
      })
  }
 
  
    if (isLoading2) return <Loader />;
    if (isError2) return <Typography>Error...</Typography>;
  return (
    <Box sx={{postion:"relative", background: "transparent"}}>
        <ModalProduct item={selectedItem} open={openModal} handleClose={handleClose} type="predef" items={items} setItems={setItems} />
            {products.length > 0 && (
            <Box sx={{ width: "100%", display: "flex", gap:"10px",flexDirection: isMobile ? "column-reverse":"row"}}>
                <Grid container rowSpacing={{xs: 1, sm: 1, md: 2 }} columnSpacing={2} sx={{flex: "auto",position: "relative", background:"transparent", padding:"0 10px", marginBottom: "40px"}}>
                    {products?.filter( (pr) => {
                        if (pr.name.includes(ctxt.recherche)){
                            return pr;
                        }
                        return false;
                    }).map((produit : any) => {
                        let trouve = false
                        let init = 1
                        items?.map((el) => {
                            if (el.id === produit?.id){
                            trouve = true;
                            init = el.quantity
                            }
                        })
                        return (
                        <CardPanierPredef key={produit.id} produit={produit} trouve={trouve} handleDelete={handleDelete} handleClick={() => {
                            clickProduit({...produit, quantity:init})
                        }} />
                        
                    ) })}
                </Grid>
                <Box sx={{ background: "#fff", height: "fit-content", display: "flex", flexDirection: "column", gap:"10px", padding: "20px 10px", borderRadius:"11px"}}>
                    <Box sx={{display: "flex", gap:"5px"}}>
                    <TextField
                    required
                    fullWidth
                    id="outlined-required"
                    name="nom"
                    label="Nom du panier"
                    placeholder="Nom du panier"
                    type="text"
                    defaultValue={name}
                    onChange={(e: any) => {
                        setName(e.target.value)
                    }}
                    size="small"
                    sx={{backgroundColor: "#ffffff"}}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">{''}</InputAdornment>
                        ),
                      }}
                    />

                    </Box>
                    <Box sx={{ display:"flex",gap:"10px"}}>
                        {
                            days.map((day) => {
                                if (values[day.id - 1]=== false) return <Box key={day.id} sx={{ color:"#929292", background: "#D9D9D9", borderRadius: "50%",cursor:"pointer", padding: "5px 10px" }} onClick={() => {
                                    const t = values
                                    t[day.id - 1] = true
                                    setValues(t)
                                    setRefresh(!refresh)
                                }}>
                                <Typography>{day.label}</Typography>
                            </Box>
                            if (values[day.id - 1]=== true) return <Box key={day.id} sx={{ color:"#fff", background: "#92CE08", borderRadius: "50%", padding: "5px 10px", cursor:"pointer" }} onClick={() => {
                                    const t = values
                                    t[day.id - 1] = false
                                    setValues(t)
                                    setRefresh(!refresh)
                                }}>
                                <Typography>{day.label}</Typography>
                            </Box>
                                
                            })
                        }
                    </Box>
                    <Box sx={{display:"flex", width:"100%", gap:"10px"}}>
                        
                        <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{
                        flex:1,
                        height: 40,
                        borderRadius: "3px",
                        fontSize: "10px",
                        fontWeight: "500",
                        margin:"0 auto"
                        }}
                        onClick={() => {
                            handleSubmit()
                        }}
                        >
                            Mettre a jour
                        </Button>
                    </Box>
                    <Switch
                    name="state"
                    checked={state}
                    onChange={(e) => {
                        disablePanier()
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    />
                </Box>
            </Box>
            )}
            {products.length===0 && (
                <Typography>Aucun produit trouvé</Typography>
            )}
    </Box>
  )
}

export default PanierPredef