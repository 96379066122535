
import {
    Grid,
    Box,
    Typography,
    Button,
  } from '@mui/material';

import axios from 'axios';
import { useNavigate,useParams } from "@pankod/refine-react-router-v6";
import {  useOne  } from "@pankod/refine-core";
import Loader from 'components/common/Loader';
import {  useCtxt } from "contexts/app.context";
import useMediaQuery from '@mui/material/useMediaQuery';
import Swal from 'sweetalert2';
import moment from 'moment';
import { mobileWidth } from "components/constante";

const Commande = () => {
    const { id } = useParams();
    const { ctxt } = useCtxt();  
    const isMobile = useMediaQuery(mobileWidth)  
    const navigate = useNavigate();
    
    const { data, isLoading, isError }= useOne({
        resource: "orders",
        id: id as string,
    });
    
    
   
    /* @ts-ignore */
    const cmd = data?.data ?? {};
    const handleClick = async () => {
        await axios.put("/api/orders/cancel/"+cmd.id,{},{
            withCredentials: true,
          }).then((res) => {
            if (res.status === 200) {
                Swal.fire("Succes", "Votre commande a bien été annulée", "success");
                navigate("/commandes")
              }
        })
        .catch((e) => {
            Swal.fire("Erreur", "La commande n'a pas été annulée", "error");
        })
    }
    
   
 
    if (isLoading) return <Loader />;
    if (isError) return <Typography>Error...</Typography>;
  return (
    <Box sx={{ display:"flex",  width: "100%",height: "100%",paddingBottom:"50px"}}>
    {  <Box sx={{width: "100%", position: "relative", height: "100%", display: "flex",flexDirection: isMobile ? "column":"row", justifyContent: isMobile ? "space-between":"center", margin: "10px 0"}}>
    <Grid rowSpacing={{xs: 1, sm: 1, md: 1 }} columnSpacing={{xs: 0, md:2}} sx={{position: "relative", flex:"auto", gap:"20px", margin: "0px", background:"transparent", padding:"0 10px", display: "flex", flexDirection: "column"}}>
        {cmd.orders_details.map((order : any) => {return (
            <Box key={order.id} sx={{display:"flex", justifyContent:"space-between", padding:"10px 50px"}}>
                <Box sx={{ display:"flex", flexDirection:"column"}}>
                    <Box sx={{display:"flex",gap:"5px"}}>
                        <Typography sx={{fontWeight:"bold"}}>{order.quantity} X</Typography>
                        <Typography>{order.name}</Typography>
                    </Box>
                    <Typography>. Prix Unitaire: {order.pricePromotion ? order.pricePromotion : order.price}</Typography>
                    <Typography>. Poids Total: {order.unit}</Typography>
                </Box>
                <Typography sx={{color:"#92CE08"}}>{order.pricePromotion ? order.pricePromotion : order.price} DA</Typography>
            </Box>
            
        ) })}
    </Grid>
        
        <Box sx={{ display:"flex", flexDirection:"column",padding:"12px 14px", alignItems:"flex-start",gap:"10px",height:"fit-content",borderRadius:"10px", background:"#fff",margin: isMobile ? "0 30px":"0"}}>
                <Box sx={{display: "flex", justifyContent:"space-between", gap:"10px", width:"100%"}}>
                    <Typography >#com{cmd.id}</Typography>
                    <Typography sx={{color: "#949494"}}>{moment(cmd.created_at).format("YYYY/MM/DD HH:mm")}</Typography>
                </Box>
                {
                    cmd.state === "En attente" && <Typography sx={{color:"#F9A400"}}>En cour de traitement </Typography>
                }
                {
                    cmd.state === "En préparation" && <Typography sx={{color:"#E56E00"}}>En cour de préparation </Typography>
                }
                {
                    cmd.state === "Livrée" && <Typography sx={{color:"#92CE08"}}>livrée </Typography>
                }
                {
                    cmd.state === "Annulée" && <Typography sx={{color:"#C80000"}}>Annulée</Typography>
                }
                
                <Typography sx={{fontWeight:"bold"}}>TOTAL TC:{cmd.totalPrice} DA</Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    disabled={cmd.state !== "En attente"}
                    sx={{
                    height: 20,
                    borderRadius: "3px",
                    fontSize: "10px",
                    fontWeight: "500",
                    margin:"0 auto",
                    fontWweight: 600,
                    padding: "20px 46px"
                    }}
                    onClick={() => {
                    handleClick()
                    }}
                >
                    Annuler
                </Button>
            </Box>
    </Box>}
    
    </Box>
  )
}

export default Commande